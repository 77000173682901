@tailwind components;

.yield-quantity-input .syt-antd-input-wrapper-default {
  width: 50%;
}

.yield-quantity-input .syt-antd-input-wrapper-default input,
.yield-quantity-input .syt-antd-input-affix-wrapper {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  margin: 0;
}

.yield-quantity-input .syt-antd-input-wrapper-default input:focus,
.yield-quantity-input .syt-antd-input-wrapper-default .syt-antd-input-affix-wrapper:focus {
  outline: 0;
  box-shadow: none;
}

.yield-quantity-input
  .syt-antd-select-single:not(.syt-antd-select-customize-input)
  .syt-antd-select-selector {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: 0;
}

.yield-quantity-input input.border-remove {
  border-color: #cf3537;
}
