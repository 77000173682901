.failed-submission-drawer {
  .syt-antd-drawer-content-wrapper {
    width: 574px !important;
  }
  .syt-antd-drawer-header {
    padding: 16px 24px;
  }
  .syt-antd-drawer-body {
    padding: 0;
  }
  .syt-antd-drawer-title {
    padding-top: 16px;
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  .syt-antd-drawer-close {
    position: absolute;
    right: 0;
    top: 0;
    padding: 0;
    margin: 16px 16px 0 0;
  }

  .syt-antd-table-wrapper {
    box-shadow: none !important;
    border-radius: 0 !important;
    border: 0 !important;
  }
  .syt-antd-table-thead {
    .syt-antd-table-cell::before {
      content: none !important;
    }
  }

  .syt-antd-table-tbody {
    .syt-antd-table-cell {
      padding: 6px 24px;
    }
  }
  .syt-antd-table-cell {
    padding: 12px 24px;
  }
  .syt-antd-table-cell:has(.failed-submission-drawer-table-red-row) {
    padding: 4px 24px;
    background-color: #9C2628;
  }
  .syt-antd-table-cell:has(.failed-submission-drawer-table-red-row):hover {
    background-color: #9C2628!important;
  }

  .syt-antd-drawer-footer{
    border-top: 1px solid #DFE2E7;
    padding: 9px 24px;
  }
}
