.selected-icon {
  filter: brightness(0) saturate(100%) invert(32%) sepia(42%) saturate(3204%) hue-rotate(189deg) brightness(89%) contrast(101%);
}

.yield-option:hover img {
  filter: brightness(0) saturate(100%) invert(32%) sepia(42%) saturate(3204%) hue-rotate(189deg) brightness(89%) contrast(101%);
}

.yield-select-arrow {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3E%3Cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3E%3C/svg%3E");
}
