.syt-antd-tree-treenode,
.syt-antd-tree-node-content-wrapper {
  width: 100%;
}
.syt-antd-tree-indent {
  width: 0;
}

.syt-antd-tree-switcher {
  position: absolute;
  z-index: 64;
  height: 58px;
  padding-top: 8px;
  padding-left: 8px;
  &.syt-antd-tree-switcher-noop {
    display: none;
  }
}

.syt-antd-tree .syt-antd-tree-treenode {
  padding: 0;
}

.syt-antd-tree-indent:has(.syt-antd-tree-indent-unit)
  ~ .syt-antd-tree-switcher {
  padding-left: 24px;
  width: 34px;
}

.syt-antd-tree .syt-antd-tree-node-content-wrapper.syt-antd-tree-node-selected {
  background-color: #eaf6ff;
}

.button-background {
  cursor: not-allowed;
  background-color: #dfe2e7 !important;
  color:#677683
}