.syt-antd-table-wrapper {
  .hide-checkbox {
    .syt-antd-table-selection-column {
      display: none;
    }
    .syt-antd-table-cell {
      padding: 0;
    }
  }
}
