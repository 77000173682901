.base-drop-down-search-input .syt-antd-input, .base-drop-down-search-input .syt-antd-input:focus, .base-drop-down-search-input .syt-antd-input:hover{
    background: transparent;
}

.items-drop-down-field .gr-button{
    padding: .375rem 0rem !important;
}

.items-drop-down-field-1 .gr-button{
    padding: 1rem 0rem !important;
}