.select-option.syt-antd-select-item-option-selected .anticon {
  display: none;
}

.select-container .syt-antd-select-multiple .syt-antd-select-selection-item {
  margin: 0;
  padding: 0;
  border: none;
  height: 22px;
}
.select-container
  .syt-antd-select-multiple
  .syt-antd-select-selection-item-content {
  margin: 0;
}

.select-container .syt-antd-select-selection-item {
  height: 100%;
}

.select-container .syt-antd-select-selection-item-content {
  margin: 0;
}

.select-container
  .syt-antd-select:not(.syt-antd-select-customize-input)
  .syt-antd-select-selector {
  border: 1px solid #c2c7d0;
}

.select-container .syt-antd-select-selection-placeholder {
  color: #696f88;
}
