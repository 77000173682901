.base-drop-down-search-input #label-search-input {
  padding-left: 20px;
}

.base-drop-down-search-input .prefix-icon {
  z-index: 99;
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
