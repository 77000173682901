.save-all-modal .syt-antd-modal-header {
  border-bottom: none;
  padding: 0px;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}

.save-all-modal .syt-antd-modal-title {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}

.save-all-modal .syt-antd-modal-footer {
  border-top: none;
}

.save-all-modal .syt-antd-modal-body {
  padding-right: 0px;
}

.save-all-modal .syt-antd-modal-content {
  padding: 24px;
}

.save-all-modal .syt-antd-btn-primary {
  height: 100%;
  border-color: #c2c7d0;
  background: #14803c;
  padding: 10px 16px 10px 16px !important;
}

.overdrawn-source .syt-antd-btn-primary {
  height: 100%;
  border-color: #c2c7d0;
  background: #cf3537;
  padding: 10px 16px 10px 16px !important;
}

.save-all-modal .syt-antd-btn-default {
  height: 100%;
  border-color: #c2c7d0;
  padding: 10px 16px 10px 16px !important;
}
