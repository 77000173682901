.add-product-drawer {
  .syt-antd-drawer-body {
    padding: 0;
  }
  .syt-antd-layout {
    background: white;
    .syt-antd-layout-header {
      height: auto;
      padding: 0;
      line-height: normal;
    }
  }
  .syt-antd-drawer-footer {
    padding: 8px 32px;
    border-top: 1px solid var(--neutral-neutral-20, #dfe2e7);
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
  }
}

.flex-shrink .syt-antd-table-wrapper {
  border-radius: 0 !important;
  box-shadow: none !important;
}
