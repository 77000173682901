.product-detail-status-submission-banner {
  animation: product-detail-status-submission-banner-move-up 1s ease-out
    forwards;
  overflow: hidden; /* Ensure content is hidden when collapsing */
}

@keyframes product-detail-status-submission-banner-move-up {
  from {
    max-height: 100px; /* Set this to the initial height of the element */
  }
  to {
    max-height: 0;
    padding-top: 0; /* Adjust padding to remove any extra space */
    padding-bottom: 0;
    margin-top: 0; /* Adjust margin if needed */
    margin-bottom: 0;
  }
}
