.load-product-drawer .syt-antd-drawer-header-title {
  display: flex;
  flex-flow: row-reverse;
}
.load-product-drawer .syt-antd-drawer-close {
  margin: 0;
  padding: 0;
}

.load-product-drawer .syt-antd-drawer-header {
  padding-top: 12px;
  padding-right: 12px;
  padding-bottom: 0px;
}

.load-product-drawer .syt-antd-drawer-body {
  margin-left: 32px;
  margin-right: 32px;

  padding: 0;
}

.load-product-drawer .syt-antd-drawer-footer {
  border-top: 1px;
  border-style: solid;
  border-color: #f3f4f6;
}
