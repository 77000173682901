.custom-info.syt-antd-alert-with-description {
  color: #14151c;
}

.cwf-add-location-drawer .syt-antd-drawer-footer {
  padding: 9px 24px;
  border-top: 1px solid #dfe2e7;
}

.location-add-save-button.syt-antd-btn[disabled] {
  border-color: #dfe2e7;
}

.cwf-add-location-drawer .syt-antd-drawer-body {
  margin: 0;
}

.cwf-add-location-drawer .syt-antd-input-caption {
  color: rgb(207, 53, 55);
}
