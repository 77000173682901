.fixed-footer-manipulation {
  position: fixed;
  bottom: 0;
  right: 0;
  width: calc(100vw - 64px);
  transition-duration: 0.2s;
  transition-property: width;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.sidebar-opened {
  width: calc(100vw - 256px);
}
