.weight-dropdown .dark-blue path {
  fill: #0071cd;
}
.weight-dropdown .syt-antd-select-arrow {
  right: 0; /* space between dropdown and chevron */
}

.weight-dropdown .syt-antd-select-focused {
  box-shadow: none;
}

.weight-dropdown
  .syt-antd-select-single.syt-antd-select-open
  .syt-antd-select-selection-item {
  color: inherit;
}

.yield-load-alerts .syt-antd-alert-icon {
  margin-left: 8px;
}

.yield-load-alerts .syt-antd-alert-message {
  position: static !important;
}
