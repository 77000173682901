.ant-switch:focus,
.ant-switch-checked:focus,
.ant-switch-inner:focus {
  box-shadow: none !important;
  outline: none !important;
}

.ant-switch-checked {
  background-color: #096dd9 !important;
  border-color: #096dd9 !important;
}
