.action-button:hover img {
  color: #14803c;
  filter: hue-rotate(80deg) invert(100%);
}
.compact-action-button:focus {
  outline: none;
}
.compact-action-button:hover {
  transition: 0.3s;
  background-color: #eaf6ff;
  img {
    filter: invert(36%) sepia(91%) saturate(374%) hue-rotate(173deg)
      brightness(90%) contrast(103%);
  }
}
