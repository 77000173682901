.SquareDropdown {
    display: flex;
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    border-radius: 8px;
    background: #EAF6FF;
    width: 260px;
}

.calendarCropYear {
    color: #0092E4;
}

.warningBox {
    padding: 8px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 4px;
    border: 1px solid #F0C355;
    background: #FFF3DD;
}