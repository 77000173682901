.mapboxgl-canvas {
  width: 100% !important;
  height: 100% !important;
}

.mapboxgl-ctrl-geocoder {
  height: 1.5rem !important;
  margin: 0 20px 100px 0 !important;
}

.mapboxgl-ctrl-geocoder.mapboxgl-ctrl-geocoder--collapsed {
  min-width: 1.6rem;
  width: 1.6rem;
}

.mapboxgl-ctrl-geocoder--input {
  height: 1.5rem;
}

.mapboxgl-ctrl-geocoder--icon {
  top: 2px;
  left: 3px;
}

.mapboxgl-ctrl-geocoder--icon-close {
  margin-top: -6px;
}

.mapboxgl-ctrl-geocoder--input {
  margin-top: -1px;
}

.mapboxgl-ctrl-group {
  margin: 35px 20px -65px 3px !important;
}

.mapboxgl-ctrl-geolocate {
  width: 1.6rem !important;
  height: 1.5rem !important;
}

.mapboxgl-ctrl-icon {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='29' height='29' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg' fill='%23696F88'%3E%3Cpath d='M10 4C9 4 9 5 9 5v.1A5 5 0 005.1 9H5s-1 0-1 1 1 1 1 1h.1A5 5 0 009 14.9v.1s0 1 1 1 1-1 1-1v-.1a5 5 0 003.9-3.9h.1s1 0 1-1-1-1-1-1h-.1A5 5 0 0011 5.1V5s0-1-1-1zm0 2.5a3.5 3.5 0 110 7 3.5 3.5 0 110-7z'/%3E%3Ccircle cx='10' cy='10' r='2'/%3E%3C/svg%3E") !important;
}

.mode-drag_circle .mapboxgl-canvas-container {
  cursor: crosshair;
}

.mapboxgl-ctrl-attrib.mapboxgl-compact {
  margin: 0;
}

.static-map {
  .mapboxgl-canvas-container.mapboxgl-interactive {
    cursor: default;
  }

  .mapboxgl-ctrl-attrib,
  .mapboxgl-ctrl-logo {
    display: none;
  }
}
