.commodity-dropdown {
  padding-top: 3px;
}

.commodity-dropdown .dark-blue path {
  fill: #0071cd;
}
.commodity-dropdown .syt-antd-select-arrow {
  right: 0; /* space between dropdown and chevron */
}

.commodity-dropdown .syt-antd-select-focused {
  box-shadow: none;
}

.commodity-dropdown
  .syt-antd-select-single.syt-antd-select-open
  .syt-antd-select-selection-item {
  color: inherit;
}

.commodity-dropdown .syt-antd-select-selection-item .crop-name {
  color: #0071cd;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: -0.3px;
}

.commodity-dropdown .syt-antd-select-selection-item img,
.commodity-drowpdown-options .syt-antd-select-item-option-selected:not(.syt-antd-select-item-option-disabled)
  img {
  filter: brightness(0) saturate(100%) invert(32%) sepia(42%) saturate(3204%)
    hue-rotate(189deg) brightness(89%) contrast(101%);
}

.commodity-drowpdown-options .syt-antd-select-item-option-selected:not(.syt-antd-select-item-option-disabled) {
  color: #0071cd;
}
