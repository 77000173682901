.white-bg {
  background: #fff;
}

.popup-width-mass-assigner {
  width: 100%;
}

[aria-label='menu'] {
  margin-bottom: 0 !important;
}

[aria-label='menu'] > li > button {
  width: 100% !important;
  text-align: left;
  height: 100%;
}

[aria-label='menu'] > li > button > div {
  display: flex;
  align-items: center;
  height: 100%;
}

[aria-label='menu'] > li {
  padding: 0 0.25rem;
  height: 2rem;
}

[aria-label='menu'] > li:hover {
  background: #edf2f7;
}

[aria-label='popup'] {
  width: 100%;
}

[data-tree='true'] > div {
  border-right: none !important;
  border-left: none !important;
}

.edit-icon-button {
  width: 1.25rem !important;
  height: 1.25rem !important;
}

.w-full-panel {
  width: calc(100% + 2rem);
  margin-left: -1.75rem;
}

.-h-24 {
  height: calc(100% - 5.25rem);
}

.top-header-spinner * {
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto !important;
}

@media (max-width: 1279px) {
  .top-header-properties .text-base {
    font-size: 0.875rem !important;
  }

  .top-header-properties .text-2xl {
    font-size: 1.125rem !important;
  }
}

[data-testid='property-table'] ::placeholder {
  font-size: 1rem;
}

.cwf-property-sticky-table .gr-table {
  table-layout: fixed;
  min-width: 1285px;
}

.cwf-property-sticky-table .gr-table-row-group > div {
  z-index: 0;
}

.cwf-property-sticky-table .gr-table-row:first-child {
  background: #f3f4f6;
  top: -1px !important;
}

.cwf-property-sticky-table .gr-table-row:not(:first-child):hover {
  background: transparent;
}

/* table body */
.cwf-property-sticky-table [data-testid='landing-table'] {
  max-height: 400px !important;
  overflow: scroll;
}

.cwf-property-sticky-table .gr-table-row-group .gr-table-row:first-child {
  top: 0;
  z-index: 2;
  position: -webkit-sticky;
  position: sticky;
}

.cwf-property-sticky-table
  .gr-table-row-group
  > div
  .gr-table-cell:first-child {
  background: white;
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  z-index: 1;
  text-align: left;
}

/* table header */
.cwf-property-sticky-table
  .gr-table-row-group
  > div:first-child
  .gr-table-cell:first-child {
  background: #f3f4f6;
}

.cwf-property-sticky-table .number-content > div:first-child > div:first-child {
  display: flex;
  justify-content: flex-end;
}

.cwf-property-sticky-table
  .gr-table-row-group
  > div:first-child
  .gr-table-cell:first-child
  > div,
.cwf-property-sticky-table
  .gr-table-row-group
  > div:not(:first-child)
  .gr-table-cell:first-child
  > div {
  box-shadow: 2px 0px 4px rgba(0, 0, 0, 0.1);
  border-right: 1px solid #ededed;
}

[data-testid='property-table'] .gr-p-4.gr--mt-px {
  padding-left: 0;
  padding-right: 0;

  .syt-antd-table-wrapper,
  .syt-antd-pagination {
    border: 0 !important;
    border-radius: 0 !important;
    box-shadow: none;
  }

  .syt-antd-table-wrapper {
    overflow: visible;
  }

  .syt-antd-table-container {
    border-bottom: 1px solid #dfe2e7 !important;
  }

  th[style*='text-align: right;'] span.syt-antd-table-column-title {
    width: 100%;
  }

  td.syt-antd-table-cell {
    padding-top: 6px;
    padding-bottom: 6px;
  }

  .syt-antd-table-row:hover > td {
    background: white !important;
  }

  .syt-antd-pagination.syt-antd-table-pagination {
    padding: 0;
    padding-top: 12px;
    width: 100%;
    .syt-antd-pagination-total-text {
      width: 100%;
    }
  }
}

.chevron-disabled path {
  fill: #c2c7d0;
}

.cwf-property-not-sticky [data-testid='landing-table'] {
  max-height: 400px !important;
  overflow: scroll;
}

.cwf-property-not-sticky .gr-table-row-group .gr-table-row:first-child {
  top: 0;
  z-index: 2;
  position: -webkit-sticky;
  position: sticky;
}

.cwf-property-not-sticky .gr-table-row-group > div .gr-table-cell:first-child {
  background: white;
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  z-index: 1;
  text-align: left;
}

/* table header */
.cwf-property-not-sticky
  .gr-table-row-group
  > div:first-child
  .gr-table-cell:first-child {
  background: #f3f4f6;
}

.cwf-property-not-sticky .number-content > div:first-child > div:first-child {
  display: flex;
  justify-content: flex-end;
}

.cwf-property-not-sticky
  .gr-table-row-group
  > div:first-child
  .gr-table-cell:first-child
  > div,
.cwf-property-not-sticky
  .gr-table-row-group
  > div:not(:first-child)
  .gr-table-cell:first-child
  > div {
  box-shadow: 2px 0px 4px -4px #bbb;
}
