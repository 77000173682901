.async-loader {
  display: inline-block;
  margin-left: 12px;
}

.async-loader .gr-w-full {
  width: auto !important;
}

.async-loader .gr-h-full {
  height: auto !important;
}
.async-loader .gr-grid {
  display: inline-block !important;
}
