.select-properties-drawer [data-testid="land-map"] > div, 
.select-properties-drawer .tree-left-panel {
  height: calc(-180px + 100vh)!important;
}

.select-properties-drawer .mapboxgl-map {
  border-radius: 4px;
}

.select-properties-drawer .syt-antd-drawer-close,
.select-properties-drawer .syt-antd-drawer-header {
  margin: 0;
  padding: 0;
}

.select-properties-drawer .syt-antd-drawer-header-title {
  justify-content: flex-end;
  padding: 12px 12px 0 0;
}