.product-list-table-action {
  cursor: pointer;
}

.product-list-table-action:hover {
  color: #14803c;
}

.product-list-table-action:hover img {
  filter: hue-rotate(80deg) invert(100%);
}
