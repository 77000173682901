.crop-select-container .syt-antd-select-selection-item .selected-icon {
  display: none;
}


.crop-select-container .syt-antd-select-item-option-selected:not(.syt-antd-select-item-option-disabled) {
  color: #14151C!important;
}

.property-crop-selection-dropdown {
  width: fit-content!important;
}