.filler-card {
  width: 432px;
  height: 253px;
  border-radius: 10px;
  background-color: white;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

.filler-image-integration {
  background-image: url('../../assets/integrations/McCainLogo.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}