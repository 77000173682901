.crops-container {
  max-height: 66vh;
  overflow-y: auto;
}

.crop-card {
  width: 33.33%;
}

@media (max-width: 1620px) {
  .crop-card {
    width: 50%;
  }
}

@media (max-width: 1125px) {
  .crop-card {
    width: 100%;
  }
}
