.uom-btn-container {
  flex-direction: row;
  align-items: flex-start;
}

@media (max-width: 1350px) {
  .uom-container {
    order: 2;
  }
  .uom-btn-container {
    flex-direction: column;
    align-items: flex-end;
  }
}
