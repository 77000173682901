.selected-property-toolbar button[value='map'] {
  width: 180px;
}

.selected-property-toolbar button[value='info'] {
  width: 200px;
}

.selected-property-toolbar  button {
  justify-content: center;
}
