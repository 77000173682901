.select-with-search-dropdown .syt-antd-select-item-option-grouped {
  padding-left: 0.75rem;
}


.select-with-search-dropdown .syt-antd-select-selector {
  padding-left: 30px !important;
}
.select-with-search-dropdown .syt-antd-select {
  width: 100%;
}

.select-with-search-dropdown .syt-antd-select-selection-search {
  padding-left: 20px;
}

.select-with-search-dropdown .prefix-icon {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  z-index: 1;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
}


.select-with-search-dropdown .syt-antd-select-selection-search-input {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.select-with-search-dropdown .syt-antd-select-selection-search-input:focus {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}
