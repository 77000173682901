.loads-table {
  .ant-table-row {
    height: auto !important;
  }

  .ant-table-body {
    min-height: 90vh;
  }

  .syt-antd-pagination-item {
    display: none;
  }

  .syt-antd-spin-nested-loading {
    height: 100%;
  }

  .syt-antd-spin-container {
    height: 100%;
  }

  .syt-antd-table {
    height: calc(100% - 4rem);
  }

  .syt-antd-pagination-total-text {
    margin-right: 16px;
  }

  .syt-antd-pagination-prev,
  .syt-antd-pagination-jump-prev,
  .syt-antd-pagination-jump-next {
    margin-right: 8px;
  }

  .syt-antd-table-column-title {
    flex: unset;
  }

  .syt-antd-table-column-sorters {
    justify-content: start;
    gap: 0.5rem;
  }

  .syt-antd-table-thead
    > tr
    > th:not(:last-child):not(.syt-antd-table-selection-column):not(.syt-antd-table-row-expand-icon-cell):not([colspan])::before {
    display: none;
  }

  .syt-antd-table-cell {
    max-width: 250px;
  }

  button.syt-antd-pagination-item-link {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &.one-page-table .syt-antd-pagination-prev,
  &.one-page-table .syt-antd-pagination-next {
    display: none;
  }
}

.syt-antd-table-wrapper {
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
  border-radius: 8px !important;
  border: 1px solid #c2c7d0 !important;
  flex: 1;
  overflow: hidden;
}

.syt-antd-table-pagination.syt-antd-pagination {
  background-color: #fff;
  margin: 0;
  padding: 12px 24px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.syt-antd-table-column-sorter-inner {
  display: flex;
  height: 18px;
}

.syt-antd-table-column-sorter-inner:after {
  content: url('../../../../../assets/unfold_more.svg');
  width: 18px;
  height: 18px;
  position: absolute;
}

.sort-asc .syt-antd-table-column-sorter-inner:after {
  content: url('../../../../../assets/expand_less.svg');
  color: black;
  filter: brightness(0) saturate(100%) invert(45%) sepia(7%) saturate(1340%)
    hue-rotate(191deg) brightness(94%) contrast(84%);
}

.sort-desc .syt-antd-table-column-sorter-inner:after {
  content: url('../../../../../assets/expand_more.svg');

  filter: invert(45%) sepia(7%) saturate(1340%) hue-rotate(191deg)
    brightness(94%) contrast(84%);
}

.syt-antd-table-column-sorter-up svg,
.syt-antd-table-column-sorter-down svg {
  display: none;
}
