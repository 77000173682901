.react-checkbox-tree label {
  display: flex;
  align-items: flex-start;
}

.react-checkbox-tree label:hover {
  background: rgba(163, 163, 163, 0.1);
}

.rct-collapse {
  min-width: 30px;
}
.react-checkbox-tree ol {
  width: 100%;
}
.react-checkbox-tree ol ol {
  padding-left: 30px;
  width: 100%;
}

.rct-text {
  align-items: start;
  width: 100%;

}
.react-checkbox-tree label {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.rct-title {
  align-items: start;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.react-checkbox-tree button {
  padding-top: 3px;
}
[type='checkbox']:checked + .rct-checkbox svg,
[type='checkbox']:indeterminate + .rct-checkbox svg {
  color: #0071cd;
}
