.yield-load-alerts .syt-antd-alert-icon {
  margin-left: 8px;
}

.yield-load-alerts .syt-antd-alert-message {
  position: static !important;
}

.border-remove {
  border-color: #cf3537 !important;
}
